import {getCityStateZip} from '../../../../utils/view-helpers/address-view-helpers';
import {locationIsDeliveryOnly, locationIsIneligible} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import {useReservation} from '../../../../hooks/use-reservation';
import ReservationStepperStoreSelectionResult from '../../reservation-stepper/reservation-stepper-store-selection-result';

const PickupStoreRow = ({pickupLocation, showMiles = true}) => {
    const {pickupLocation: selectedPickupLocation, onSelectPickupLocation} = useReservation();

    const isSelected =
        selectedPickupLocation &&
        (selectedPickupLocation.storeId
            ? Number(selectedPickupLocation.storeId) === Number(pickupLocation.fulfillmentStoreId)
            : Number(selectedPickupLocation.fulfillmentLocationId) === Number(pickupLocation.fulfillmentLocationId));

    return (
        <ReservationStepperStoreSelectionResult
            address={pickupLocation.address}
            cityStateZip={getCityStateZip(pickupLocation)}
            hasPasscode={pickupLocation.hasPasscode}
            isDeliveryOnly={locationIsDeliveryOnly(pickupLocation.fulfillmentStoreLocation?.ecommerceStatus)}
            milesAway={pickupLocation.milesAway}
            name={pickupLocation.name}
            onClick={() => onSelectPickupLocation(pickupLocation)}
            selected={isSelected}
            shopInStoreOnly={locationIsIneligible(pickupLocation.fulfillmentStoreLocation?.ecommerceStatus)}
            showMiles={showMiles}
            target="_top"
        />
    );
};

export default PickupStoreRow;
